:root {
  // Toastify
  --toastify: white;
  --toastifyBorder: #e5e5e6;
  --toastifyBtn: white;
  --toastifySuccessGradientFrom: #f4fff8;
  --toastifySuccessGradientTo: #ffffff;
  --toastifyErrorGradientFrom: #fff1ef;
  --toastifyErrorGradientTo: #ffffff;
  --toastifyWarningGradientFrom: #fff6e1;
  --toastifyWarningGradientTo: #ffffff;
  --toastifyBtnText: #19191a;
  --toastifyBtnBorder: #e5e5e6;
  --toastifyText: #0d0d0d;
  --toastifySuccessIcon: url(../src/Icons/Items/toastSuccessLight.svg);
  --toastifyErrorIcon: url(../src/Icons/Items/toastErrorLight.svg);
}
