.h-p-c-d-c-email {
  height: 100%;
  .home-page-input-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 9rem;
    border: none;
    width: 320px;
    border: 1.5px solid #ffffff1a;
    box-shadow: 0px 4px 32px 0px #a5a3a30d;
    box-sizing: border-box;
    height: 100%;
    .home-page-input {
      padding: 20px 28px;
      width: 100%;
      border: none;
      outline: none;
      font-size: 16px;
    }
    &:hover {
      border-color: black;
    }
  }
  .home-page-input-container-mobile {
    width: 100%;
    &:hover {
      border-color: transparent;
    }
  }
}
.h-p-c-d-c-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1.5rem;
  margin-top: 3rem;
  .h-p-c-d-c-name-year-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .h-p-c-d-c-name-year {
      font-family: "Archivo", sans-serif;
      font-weight: 400;
      font-size: 13px;
      color: #ffffff82;
    }
  }
  .h-p-c-d-c-launch {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .h-p-c-d-c-btn {
      height: 100%;
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // gap: 9rem;
      border: none;
      padding: 20px 28px;
      width: 320px;
      border: 1.5px solid #ffffff1a;
      box-shadow: 0px 4px 32px 0px #0000000d;
      cursor: pointer;

      .h-p-c-d-c-btn-text {
        font-family: "Archivo", sans-serif;
        color: white;
        font-weight: 600;
        font-size: 16px;
      }
      .h-p-c-d-c-btn-icon {
        height: 24px;
        width: 24px;
      }
      &:hover {
        filter: invert(1);
      }
    }
    .h-p-c-d-c-btn-disabled {
      pointer-events: none;
      background-color: #333333;
      border-color: #333333;
      .h-p-c-d-c-btn-text {
        color: #b3b3b3;
      }
      .h-p-c-d-c-btn-icon {
        opacity: 0.5;
      }
    }
  }
}
.h-p-c-d-c-email-mobile {
  transform: translateY(1.2rem);
}
