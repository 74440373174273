.lochApp {
}
* {
  margin: 0rem;
  padding: 0rem;
  font-optical-sizing: auto;
}
body {
  background-color: #756955;
}
.Toastify__toast-container {
  // width: 40rem;
  // min-width: 40rem;
  width: max-content;
}

.Toastify__toast {
  background-color: var(--toastify);
  border: 1px solid transparent;
  color: var(--toastifyText);
  padding: 10px 20px;
  border-radius: 12px;
  .Toastify__toast-body {
    div {
      text-transform: lowercase;
      display: inline-block;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    flex-direction: row-reverse;
    gap: 10px;
    margin: 0;
    padding: 0;
    color: var(--black191);
    font-family: "Archivo", sans-serif;
    font-size: 16px;
    font-weight: 600;

    .Toastify__toast-icon {
      margin: 0;
      background-image: var(--toastifySuccessIcon);
      background-size: 100% 100%;
      width: 40px;
      height: 40px;

      svg {
        display: none;
      }
    }
    &::after {
      content: "Dismiss";
      background-color: var(--toastifyBtn);
      color: var(--toastifyBtnText);
      font-size: 12px;
      border: 0.15rem solid var(--toastifyBtnBorder);
      padding: 0.7rem 1.3rem;
      border-radius: 10px;
      margin-left: 50px;
    }
  }

  &.Toastify__toast--error {
    .Toastify__toast-body {
      .Toastify__toast-icon {
        background-image: var(--toastifyErrorIcon);

        svg {
          display: none;
        }
      }
    }
  }
  &.Toastify__toast--success {
    .Toastify__toast-body {
      .Toastify__toast-icon {
        background-image: var(--toastifySuccessIcon);

        svg {
          display: none;
        }
      }
    }
  }
}
.Toastify__toast--success {
  background-image: linear-gradient(
    to bottom,
    var(--toastifySuccessGradientFrom),
    var(--toastifySuccessGradientTo)
  );
}
.Toastify__toast--error {
  background-image: linear-gradient(
    to bottom,
    var(--toastifyErrorGradientFrom),
    var(--toastifyErrorGradientTo)
  );
}

@media all and (max-width: 800px) {
  .Toastify__toast-container {
    min-width: auto !important;
    width: 100vw !important;
  }
  .Toastify__toast {
    .Toastify__toast-body {
      text-align: left;
      .Toastify__toast-icon {
        margin-left: 0.5rem;
      }
    }
  }
  .tool-tip-container {
    .arrow::before {
      top: -1px;
    }
  }
  .Toastify__toast {
    .Toastify__toast-body {
      &::after {
        margin-left: 10px;
      }
    }
  }
}
