.home-page {
  position: relative;
  color: white;
  text-align: center;

  a {
    text-decoration: none;
  }
  video {
    pointer-events: none;
  }
  .home-page-content-background-filter {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    min-height: 600px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
  .home-page-content-background {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    min-height: 600px;
    object-fit: cover;
  }

  .home-page-content {
    // overflow: hidden;
    z-index: 2;
    overflow: scroll;
    position: relative;
    .home-page-content-data {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 100vh;
      width: 100vw;
      min-height: 600px;
      padding: 0rem;
      box-sizing: border-box;
      .home-page-content-data-top-socials {
        position: absolute;
        top: 3rem;
        right: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;
        .home-page-content-data-top-socials-link {
          padding: 0;
          .home-page-content-data-top-socials-link-btn {
            cursor: pointer;
            font-weight: 500;
            font-size: 20px;
            font-family: "Archivo", sans-serif;
            text-align: left;
            opacity: 0.5;
            color: white;
            transform: translateX(-2px) translateY(-1.7px);
            &:hover {
              opacity: 1;
            }
          }
        }

        .home-page-content-data-top-social-images-icons {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }
      }
      .home-page-content-data-center {
        // transform: translateY(120px);
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 4rem;
        box-sizing: border-box;
        flex-direction: column;
        gap: 1rem;
        .h-p-c-d-c-heading {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          .h-p-c-d-c-heading-text {
            font-weight: 400;
            font-size: 54px;
            font-family: "Archivo", sans-serif;
            text-align: left;
          }
          .h-p-c-d-c-heading-btn {
            font-family: "Archivo", sans-serif;
            font-weight: 700;
            background-color: white;
            color: #000000cc;
            border-radius: 12px;
            padding: 8px 12px;
            border: none;
            font-size: 13px;
          }
        }
        .h-p-c-d-c-desc {
          font-weight: 400;
          font-size: 20px;
          font-family: "Archivo", sans-serif;
          color: #ffffff82;
          text-align: left;
        }
      }
    }
    .home-page-content-legal {
      font-family: "Archivo", sans-serif;
      background-color: #756955;
      color: #988f80;
      font-weight: 400;
      font-size: 11px;
      padding: 40px 80px;
      line-height: 0.8rem;
      text-align: left;
    }
  }
  .home-page-mobile {
    overflow: hidden;
    .home-page-content {
      .home-page-content-data {
        padding: 30px;
        justify-content: space-between;

        .home-page-content-data-top {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 15px;
          transform: translateY(30px);
          .h-p-c-d-c-heading-text {
            font-weight: 400;
            font-size: 32px;
            font-family: "Archivo", sans-serif;
            text-align: left;
          }
          .h-p-c-d-c-desc {
            font-weight: 400;
            font-size: 13px;
            font-family: "Archivo", sans-serif;
            color: #ffffff82;
            text-align: left;
          }
          .h-p-c-d-c-heading-btn {
            font-family: "Archivo", sans-serif;
            font-weight: 700;
            background-color: white;
            color: #000000cc;
            border-radius: 12px;
            padding: 8px 12px;
            border: none;
            font-size: 10px;
          }
        }
        .home-page-content-data-bottom {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 30px;
          .h-p-c-d-c-btn {
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: none;
            padding: 20px 28px;
            border: 1.5px solid #ffffff1a;
            box-shadow: 0px 4px 32px 0px #0000000d;
            cursor: pointer;
            width: 100%;
            .h-p-c-d-c-btn-text {
              font-family: "Archivo", sans-serif;
              color: white;
              font-weight: 600;
              font-size: 16px;
            }
            .h-p-c-d-c-btn-icon {
              height: 24px;
              width: 24px;
            }
          }
          .h-p-c-d-c-btn-disabled {
            pointer-events: none;
            background-color: #333333;
            border-color: #333333;
            .h-p-c-d-c-btn-text {
              color: #b3b3b3;
            }
            .h-p-c-d-c-btn-icon {
              opacity: 0.5;
            }
          }
          .h-p-c-d-c-extra {
            padding: 0px 2px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .h-p-c-d-c-name-year {
              font-family: "Archivo", sans-serif;
              font-weight: 400;
              font-size: 12px;
              color: #ffffff82;
              white-space: nowrap;
            }
            .home-page-content-data-top-socials {
              position: relative;
              top: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 0.7rem;
              width: 100%;
              .home-page-content-data-top-socials-link {
                .home-page-content-data-top-socials-link-btn {
                  opacity: 0.5;
                  font-size: 13px;
                }
              }
              .home-page-content-data-top-social-images-icons {
                cursor: pointer;
                width: 18px;
                height: 18px;
                opacity: 0.5;
              }
            }
          }
        }
      }
      .home-page-content-legal {
        padding: 30px;
      }
    }
  }
}
@media screen and (max-width: 1450px) {
  .home-page {
    .home-page-content-background {
      // object-position: right;
    }
    .home-page-content {
      .home-page-content-data {
        .home-page-content-data-top-socials {
          // right: 3.5rem;
        }
        .home-page-content-data-center {
          // padding: 3.5rem;
          zoom: 0.9;
        }
      }
    }
  }
}
