.demo-video-page {
  background-color: black;
  .demo-video-page-content {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .demo-video-page-content-video {
      height: 100%;
      max-width: 100%;
    }
  }
  .demo-video-page-mobile {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .demo-video-page-content {
      width: 100vw;
      max-width: 100vw;
      height: auto;
      .demo-video-page-content-video {
        height: unset;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
